import {
  HeaderContainer,
  HeaderLink,
  HeaderLinkContainer,
  HeaderLogo,
} from './styled';
import Hamburger from '../hamburger';
import { Link, useLocation } from 'react-router-dom';
import { links } from '../../database/nav-links';

const Header = () => {
  const location = useLocation();

  return (
    <HeaderContainer>
      <Hamburger></Hamburger>
      <HeaderLogo>Ryan Blakely</HeaderLogo>
      <HeaderLinkContainer>
        {links && links.map((link) => 
        <HeaderLink isActive={location.pathname === link.url}>
            <Link to={link.url}>{link.title}</Link>
        </HeaderLink>
        )}
      </HeaderLinkContainer>
    </HeaderContainer>
  );
};

export default Header;
