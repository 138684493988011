export const links = [
  {
    url: '/',
    title: 'Projects'
  },
  {
    url: '/experience',
    title: 'Experience'
  }
];
