import { Subtitle, Title } from '../../common/styled';
import {
  ProjectContainer,
  ProjectDetails,
  ProjectLogo,
} from './styled';

export interface ProjectType {
  title: string;
  logoUrl: string;
  webUrl: string;
  description: string;
}

export interface ProjectObject {
  project: ProjectType;
}

const Project2 = (props: ProjectObject) => {
  const { project } = props;
  const { title, logoUrl, webUrl, description } = project;

  const openUrlInNewTab = () => {
    window.open(webUrl, '_blank')
  }

  return (
    <ProjectContainer onClick={openUrlInNewTab}>
      <ProjectLogo src={logoUrl}></ProjectLogo>
      <ProjectDetails>
        <Title href={webUrl} target="_blank">
          {title}
        </Title>
        <Subtitle>{description}</Subtitle>
      </ProjectDetails>
    </ProjectContainer>
  );
};

export default Project2;
