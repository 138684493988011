import styled, { css } from 'styled-components';
import { COLORS } from '../../static/colors';

export type MenuLinkProps = {
  isActive: boolean;
};

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 1rem;  
  align-items: flex-start;
  width: 50%;
  height: 100%;
  background: white;
  top: 5em;
  position: fixed;
  padding: 0 0 0 1rem;

  @media (min-width: 800px) {
    display: none;
  }
`;

export const MenuLink = styled.span<MenuLinkProps>`
  font-size: 24px;
  cursor: pointer;
  margin: 1em 0;

  ${(props) =>
    props.isActive &&
    css`
      font-weight: 700;
      border-bottom: 2px solid ${COLORS.orange};
      padding-bottom: 5px;
    `}

  &:hover {
    font-weight: 700;
  }
`;
