import styled, { css } from 'styled-components';
import { COLORS } from '../../static/colors';

export type HamburgerProps = {
	showMenu: boolean;
}

export const HamburgerMenu = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 6px;
	font-size: 14px;
	font-weight: 700;
  cursor: pointer;
  width: 34px; 

	@media (min-width: 800px) {
    display: none;
	}    
`

export const HamburgerBar = styled.div<HamburgerProps>`
  height: 4px;
  width: 100%;
  background: ${COLORS.black};
  transition: 0.05s linear;

	${props => props.showMenu && css`
    // hide middle line
    &:nth-child(2) {
      opacity: 0;
      transform: rotate(360deg) scale(0.2);    
    }

    // rotate other 2 lines to form x
    &:first-child {
      transform: rotate(45deg) translate(6px, 8px);
    }

    &:last-child {
      transform: rotate(-45deg) translate(6px, -8px);
    }    
	`}

  &:hover {
	  font-weight: 700;
  }
`