import styled, { css } from 'styled-components';
import { COLORS } from '../static/colors';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4em;
  padding: 2em;

  @media (min-width: 800px) {
    gap: 5em;
    padding: 2em;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  column-gap: 5px;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 5px;
  row-gap: 1em;
`;

export const Title = styled.a`
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
`;

export const Subtitle = styled.div`
  font-size: 12px;
  color: ${COLORS.black};
`;

export const Section = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 2em;
  text-align: left;
  width: 100%;
`;

export const Text = styled.p`
  color: ${COLORS.black};
`;

// OLDER STUFF BELOW MAY NOT BE IN USE

export const Background = styled.div`
  background: white;

  ${(props) =>
    props.theme.dark &&
    css`
      background: ${props.theme.colors.darkBlack};
    `}
`;

export const H1Text = styled.h1`
  color: #333;

  ${(props) =>
    props.theme.dark &&
    css`
      color: ${props.theme.colors.white};
    `}
`;

export const H2Text = styled.h2`
  color: #333;

  ${(props) =>
    props.theme.dark &&
    css`
      color: ${props.theme.colors.white};
    `}
`;

export const H3Text = styled.h3`
  color: #333;

  ${(props) =>
    props.theme.dark &&
    css`
      color: ${props.theme.colors.white};
    `}
`;

export const SmallText = styled.p`
  color: ${COLORS.black};
  font-size: 12px;

  ${(props) =>
    props.theme.dark &&
    css`
      color: ${props.theme.colors.white};
    `}
`;

export const LinkText = styled.a`
  color: #333;

  ${(props) =>
    props.theme.dark &&
    css`
      color: ${props.theme.colors.white};
    `}
`;

export const Container = styled.div`
  display: flex;
  background: ${COLORS.white};
  color: ${COLORS.black};

  ${(props) =>
    props.theme.dark &&
    css`
      background: ${props.theme.colors.darkBlack};
      color: ${props.theme.colors.white};
    `}
`;
