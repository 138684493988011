import useUser from '../../hooks/useUser';

import { HamburgerBar, HamburgerMenu } from './styled';

const Hamburger = () => {
  const { showMenu, setShowMenu } = useUser();

  const toggleMenu = (currentValue: boolean) => {
    setShowMenu(!currentValue);
  };

  return (
    <HamburgerMenu onClick={() => toggleMenu(showMenu)}>
      <HamburgerBar showMenu={showMenu}></HamburgerBar>
      <HamburgerBar showMenu={showMenu}></HamburgerBar>
      <HamburgerBar showMenu={showMenu}></HamburgerBar>
    </HamburgerMenu>
  );
};

export default Hamburger;
