import { createContext, useContext, useEffect, useState } from 'react';

const UserContext = createContext({} as any);

export const useUser = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  useEffect(() => {
    console.log({ showMenu });
    if (showMenu) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [showMenu]);

  return {
    showMenu,
    setShowMenu,
  };
};

export function UserProvider({ children }: { children: any }) {
  const user = useUser();
  return <UserContext.Provider value={user}> {children} </UserContext.Provider>;
}

export default function UserConsumer() {
  return useContext(UserContext);
}
