export const projects = [
  {
    title: 'Groove',
    logoUrl:
      'https://res.cloudinary.com/dccqw6mij/image/upload/v1677369532/u1xg9av9i17t6pofulrq.png',
    webUrl:
      'https://apps.garmin.com/en-US/apps/e435b71f-a27f-4d54-a815-7b65611864b2',
    description: 'Watch face for Garmin',
    background: '#1E2837',
  },
  {
    title: 'Magfinder.com',
    logoUrl: 'https://res.cloudinary.com/dccqw6mij/image/upload/v1677369681/nr0rcmgybfyli63i9jjb.svg',
    webUrl: 'https://www.magfinder.com',
    description: 'New Independent Magazines',
    background: 'orange'
  },
  {
    title: 'VR Vision Exercises',
    logoUrl: 'https://res.cloudinary.com/dccqw6mij/image/upload/v1646862254/qdllerjnb9h8wok7ouju.svg',
    webUrl: 'https://www.youtube.com/watch?v=9qJFNoTSZfY',
    description: 'Strengthen eye/brain connection',
    background: '#f5f5ee',
  },
  // {
  //     title: 'Garmin Watchface',
  //     icon: 'https://res.cloudinary.com/dccqw6mij/image/upload/v1661307866/fk1ig8lnrbymyizevjef.svg',
  //     url: 'https://www.youtube.com/watch?v=9qJFNoTSZfY',
  // },
];
