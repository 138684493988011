import { FunctionComponent } from 'react';
import { PageContainer, Section } from '../../common/styled';
import { projects } from '../../database/projects';
import Project2, { ProjectType } from '../../components/project2';

import SideMenu from '../../components/sidemenu';

const Home: FunctionComponent = () => {
  return (
    <PageContainer>
      <Section>
        {projects &&
          projects.map((project: ProjectType) => (
            // <Project project={project}></Project>
            <Project2 project={project}></Project2>
          ))}
      </Section>
      <SideMenu></SideMenu>
    </PageContainer>
  );
};

export default Home;
