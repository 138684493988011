export const experiences = [
  {
    company: 'Amazon',
    title: 'Frontend Engineer',
    startDate: 'June 2022',
    endDate: 'Present',
    accomplishments: [
      'Developed customer support tools and consumer facing features',
      'Scale: 1,000,000+ users',
    ],
  },
  {
    company: 'Elsevier',
    title: 'Frontend Engineer',
    startDate: 'June 2019',
    endDate: 'March 2021',
    accomplishments: [
      'Implemented WCAG 2.1 AA accessibility standards',
      'Converted legacy AngularJS app to React v16.8',
      'Scale: 10,000+ users',
    ],
  },
  {
    company: 'Greg (iOS App)',
    title: 'UX Researcher',
    startDate: '2020',
    accomplishments: [
      'Led UX research for a startup',
      'Scale: 100+ users',
    ],
  },
  {
    company: 'StoneTimberRiver',
    title: 'Software Engineer',
    startDate: 'Jan 2017',
    endDate: 'Dec 2019',
    accomplishments: [
      'Built tools to help NFL, NBA, and MLS sports teams manage their sales pipelines',
      'Won company-wide hackathon',
      'Scale: 1,000+ users',
    ],
  },
];
