import styled from 'styled-components';
import { COLORS } from '../../static/colors';

export type ProjectBackgroundrProps = {
	background: string;
}


export const ProjectLogo = styled.img`
	width: 130px;
`

export const ProjectBackground = styled.a<ProjectBackgroundrProps>`
  display: flex;
  justify-content: center;
  background: ${props => props.background};
  padding: 4em;
  border-radius: 4px;
  cursor: pointer;
`

export const ProjectDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.25em 0.75em;
  background: #ddd;
  border-radius: 3px;
  font-size: 16px;
  text-align: center;

  & > :first-child { 
    margin-bottom: .25rem;
  }  

  &:hover {
    background: ${COLORS.orange};

    a {
      color: ${COLORS.white};
      font-weight: 500;
    }
  }
`

export const ProjectContainer = styled.div`
  display: grid;
  max-width: 130px;
  justify-items: center;
  row-gap: 1em;
  cursor: pointer;
  &:hover {
    .title {
        background: ${COLORS.black};
    }

    // change title and description text white on hover
    a, div {
      color: ${COLORS.white};
    }

    ${ProjectDetails} {
      background: ${COLORS.orange};
    }    
  }   

	@media (min-width: 800px) {
    max-width: 425px;
	}  
`