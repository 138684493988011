import styled from 'styled-components';

export const ExperienceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

export const ExperienceSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 1em;
`;

export const ExperienceDateSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

export const ExperienceDate = styled.p`
  font-size: 12px;
  font-style: italic;
`;

export const ExperienceDetail = styled.li`
  font-size: 12px;
  list-style-type: disc;
  margin-left: 2em;
`;
