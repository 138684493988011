import { Link, useLocation } from 'react-router-dom';
import { MenuContainer, MenuLink } from './styled';
import useUser from '../../hooks/useUser';
import { links } from '../../database/nav-links';

const SideMenu = () => {
  const location = useLocation();
  const { showMenu, setShowMenu } = useUser();

  const handleClick = () => {
    setShowMenu(false);
  };

  return (
    <>
      {showMenu && (
        <MenuContainer>
          {links && links.map ((link) =>
            <MenuLink onClick={handleClick} isActive={location.pathname === link.url}>
              <Link to={link.url}>{link.title}</Link>
            </MenuLink>
          )}
        </MenuContainer>
      )}
    </>
  );
};

export default SideMenu;
