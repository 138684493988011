import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { COLORS } from './static/colors';
import Home from './pages/home';
import { UserProvider } from './hooks/useUser';
import Experience from './pages/experience';

function App() {
  let theme = {
    colors: COLORS,
    dark: true,
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <UserProvider>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/experience" element={<Experience />}></Route>
          </Routes>
        </UserProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
