import { FunctionComponent } from 'react';
import { PageContainer, Subtitle, Title } from '../../common/styled';
import Header from '../../components/header';
import {
  ExperienceContainer,
  ExperienceDate,
  ExperienceDateSection,
  ExperienceDetail,
  ExperienceSection,
} from './styled';
import { experiences } from '../../database/experience';
import SideMenu from '../../components/sidemenu';

const Experience: FunctionComponent = () => {
  return (
    <PageContainer>
      <Header></Header>

      <ExperienceContainer>
        {experiences &&
          experiences.map((experience) => (
            <ExperienceSection>
              <Title>{experience.company}</Title>
              <Subtitle>{experience.title}</Subtitle>
              <ExperienceDateSection>
                <ExperienceDate>{experience.startDate}</ExperienceDate>
                {experience?.endDate && (
                  <>
                    <ExperienceDate>-</ExperienceDate>
                    <ExperienceDate>{experience.endDate}</ExperienceDate>
                  </>
                )}
              </ExperienceDateSection>

              <ul>
                {experience.accomplishments &&
                  experience.accomplishments.map((accomplishment) => (
                    <ExperienceDetail>{accomplishment}</ExperienceDetail>
                  ))}
              </ul>
            </ExperienceSection>
          ))}
      </ExperienceContainer>

      <SideMenu></SideMenu>
    </PageContainer>
  );
};

export default Experience;
