import styled, { css } from 'styled-components';
import { COLORS } from '../../static/colors';

export type HeaderLinkProps = {
  isActive: boolean;
};

export const HeaderContainer = styled.header`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  padding: 1rem 0 0 1rem;
  width: 100%;

  @media (min-width: 800px) {
    flex-direction: column;
    padding: 1rem 0 0 0;
    justify-content: flex-start;
  }
`;

export const HeaderLogo = styled.h1`
  font-size: 22px;
  font-weight: 700;

  @media (min-width: 800px) {
    font-size: 32px;
  }
`;

export const HeaderLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
`;

export const HeaderLink = styled.span<HeaderLinkProps>`
  display: none;
  font-size: 16px;
  cursor: pointer;
  padding-bottom: .25rem;

  ${(props) =>
    props.isActive &&
    css`
      font-weight: 600;
      border-bottom: 2px solid;
      border-color: ${COLORS.orange};
    `}

  &:hover {
    border-bottom: 2px solid;
    border-color: ${COLORS.orange};
  }

  @media (min-width: 800px) {
    display: flex;
    flex-direction: column;
    column-gap: 1rem;
  }
`;
